<template>
  <MoleculesConsumerModal
    v-model="localValue"
    max-width="440px"
    :min-width="minScreenMd ? '440px' : '100vw'"
    :min-height="minScreenMd ? '100vh' : '60vh'"
    :max-height="minScreenMd ? '100vh' : '60vh'"
    snap-to-edge="bottom"
    pc-snap-to-edge="right"
  >
    <div :class="$style.add_cart_modal">
      <MoleculesConsumerHeading
        title="カートに商品を追加しました。"
        align="left"
        variant="sub"
        :title-font-size-px="20"
      />

      <div v-for="(cartItem, index) of cartItems" :key="cartItem.sku.document_id" :class="$style.product_item">
        <div :class="$style.product_detail">
          <AtomsConsumerImage
            :class="$style.img"
            :src="cartItem.sku.main_image"
            :alt="`${cartItem.product.name} - ${cartItem.sku.name}${
              cartItem.sku.size ? `(${cartItem.sku.size})` : null
            }の画像`"
            :width="72"
            :height="96"
          />
          <div :class="$style.product_detail_content">
            <div :class="$style.product_title">
              <h2>{{ cartItem.product.name }}</h2>
              <p>{{ cartItem.product.material }}</p>
            </div>
            <p v-if="!isEGift" :class="$style.sku_detail">
              <span v-if="cartItem.sku.size"> サイズ: {{ cartItem.sku.size }} </span>
              <span v-if="cartItem.sku.color"> / 色: {{ cartItem.sku.color }} </span>
            </p>
            <div
              v-if="
                showDuvetCollection() &&
                cartItem.product.is_duvet_collection &&
                cartItems.findIndex((item) => item.product.is_duvet_collection) === index
              "
              :class="
                duvetCollectionType === 'none'
                  ? [$style.duvet_collection_setting, $style.fit_content]
                  : $style.duvet_collection_setting
              "
            >
              <div
                v-if="duvetCollectionType === 'none'"
                :class="$style.duvet_collection_setting_none"
                @click="isDuvetCollectionModalVisible = true"
              >
                <AtomsConsumerImage src="/images/components/product/duvet_collection_guidance_3.png" :width="15" />
                <p :class="$style.duvet_collection_setting_text_1">羽毛布団の回収を希望する</p>
              </div>
              <div v-else :class="$style.duvet_collection_setting_use">
                <div :class="$style.duvet_collection_setting_text_1">羽毛布団の回収</div>
                <div
                  v-if="duvetCollectionType === 'collected_upon_delivery'"
                  :class="$style.duvet_collection_setting_text_2"
                  >お届け時に回収</div
                >
                <div v-else-if="duvetCollectionType === 'pick_up_later'" :class="$style.duvet_collection_setting_text_2"
                  >集荷を依頼</div
                >
                <AtomsConsumerButton
                  variant="primary"
                  block
                  rounded
                  :class="$style.duvet_collection_setting_btn"
                  @click="isDuvetCollectionModalVisible = true"
                >
                  変更する
                </AtomsConsumerButton>
              </div>
            </div>
            <div :class="$style.product_price">
              <p v-if="cartItem.discounted_price === cartItem.price" :class="$style.price">
                &yen; {{ priceWithComma(cartItem.reference_unit_price) }}
                <span>(参考税込)</span>
              </p>
              <template v-else>
                <p :class="[$style.price, $style.discounted]">
                  &yen; {{ priceWithComma(cartItem.reference_discounted_unit_price) }}
                  <span>(参考税込)</span>
                </p>
                <p :class="$style.strike">&yen; {{ priceWithComma(cartItem.reference_unit_price) }}</p>
              </template>
            </div>
          </div>
        </div>

        <AtomsConsumerButton
          v-if="canWrapping"
          variant="secondary"
          block
          rounded
          :class="$style.wrapping_button"
          @click="openSelectGift(cartItem.sku.document_id)"
        >
          <AtomsConsumerImage src="/images/icon/gift-wrapping.svg" alt="ギフトラッピング" :width="20" :height="20" />
          ギフトラッピングを設定する
        </AtomsConsumerButton>
      </div>
      <div :class="$style.bottom_button">
        <div :class="$style.buttons">
          <AtomsConsumerLink url="/order/cart" :class="$style.button_link">
            <AtomsConsumerButton variant="secondary" block rounded>カートを見る</AtomsConsumerButton>
          </AtomsConsumerLink>
          <AtomsConsumerLink url="/order/new" :class="$style.button_link">
            <AtomsConsumerButton variant="primary" block rounded>購入手続きへ</AtomsConsumerButton>
          </AtomsConsumerLink>
        </div>
      </div>

      <div v-if="recommendProducts.length > 0">
        <hr />
        <MoleculesConsumerHeading title="あなたへのおすすめ" align="left" variant="sub" :title-font-size-px="20" />
        <OrderRemainAmount />
        <CartRecommendCarousel
          :recommend-products="recommendProducts"
          ga-display-type="sidecart"
          :slides-to-scroll="1"
          is-vertical
        />
      </div>

      <div v-if="isShowBrowsingHistory">
        <hr />
        <MoleculesConsumerHeading title="閲覧した商品" align="left" variant="sub" :title-font-size-px="20" />
        <OrderRemainAmount />
        <PagePartsProductBrowsingHistory :class="$style.parts_blank" :slides-to-scroll="1" no-padding is-vertical />
      </div>
    </div>
    <MoleculesConsumerModal v-model="visibleSelectGift" :enable-browser-back-close="false" :class="$style.gift_modal">
      <PagePartsCartPartsSettingGift
        :gift-wrappings="selectGiftCart?.wrapping ?? []"
        :gift-messages="selectGiftCart?.message ?? []"
        :gift-cards="selectGiftCart?.card ?? []"
        :cart-detail="selectGiftCart?.cartDetail"
        :is-card-only="selectGiftCart?.cartDetail.product.is_wrapping"
        @confirm="wrappingConfirm"
        @cancel="cancelGiftModal"
      />
    </MoleculesConsumerModal>
    <OrderDuvetCollectionModal
      v-model:visible="isDuvetCollectionModalVisible"
      @cancel="isDuvetCollectionModalVisible = false"
      @done="isDuvetCollectionModalVisible = false"
    />
  </MoleculesConsumerModal>
</template>

<script setup lang="ts">
import type { ProductAndSku } from "@tential/ec-gql-schema/types/cart"
import type { CartGift, CartItem } from "@tential/ec-gql-schema/models/order"
import { useStore } from "~/stores"
import { cloneDeep } from "~/utils/functions/common"
import { priceWithComma } from "~/utils/functions/price"
import type { SkuFragmentFragment } from "~/types/type.generated"
import { useCart } from "~/composables/useCart"
import { useDuvetCollection } from "~/composables/useDuvetCollection"
import { useToast } from "~/state"
import type { CartListWithGift } from "~/types/cart"
import type { ApiRecommendProductsResponse } from "~/types/server/api/recommend/product"
import { getRecommendRequestBody } from "~/utils/functions/recommend"
import type { PagePartsProductType } from "~/types/product"

const isEGift = computed<boolean>(() => store.eGift.is_select)
const isShowBrowsingHistory = computed(() => store.productBrowsingHistory.length > 0)

const store = useStore()
const { cartState, cartLine, setWrapping } = useCart()
const { getDuvetCollectionType, showDuvetCollection } = useDuvetCollection()
const { filterIndividualGiftWrappings, filterIndividualMessages, filterIndividualCards, fetchGiftPattern } = useGift()

const { width: windowWidth } = useWindowSize()
// @mixin md と同じ値に設定
const minScreenMd = computed(() => windowWidth.value >= 768)

const localValue = computed({
  get() {
    return cartState.isShowAddCartModal
  },
  set(v) {
    cartState.isShowAddCartModal = v
  },
})

const visibleSelectGift = ref<boolean>(false)
/** ギフト編集中のカートのsku_id */
const selectGiftSkuId = ref<string>("")
/** ギフト編集中のカートがラッピング未設定か */
const isNotWrappingSelectGift = ref<boolean>(false)

// おすすめ商品
const recommendProducts = ref<PagePartsProductType[]>([])
// カート内の商品が変更されたかをチェックするためのカート情報
const latestCartList = ref<CartItem[]>([])
const cartItems = ref<ProductAndSku[]>([])
const canWrapping = ref(true)
const isDuvetCollectionModalVisible = ref<boolean>(false)

const updateRecommendProducts = () => {
  latestCartList.value = cloneDeep(store.cartList)

  // カートに商品が入っていない場合は何もしない
  if (store.cartList.length === 0) {
    return
  }

  $fetch<ApiRecommendProductsResponse>("/api/recommend/cart", {
    method: "POST",
    body: getRecommendRequestBody(5),
  }).then((results) => {
    recommendProducts.value = (results?.products ?? []) as PagePartsProductType[]
  })
}

const toast = useToast()

const cartListWithGift = computed(() => {
  const giftPatterns = cartLine.value.map((item: ProductAndSku): CartListWithGift => {
    // ラッピング済み商品・ギフト商品・ラッピング不可商品以外はギフト設定可能とする
    if (item.product.is_gift || item.product.is_wrapping) {
      return {
        wrapping: [],
        message: [],
        card: [],
        cartDetail: item,
      }
    }
    return {
      wrapping: filterIndividualGiftWrappings(item.product.document_id, null, item.sku as SkuFragmentFragment),
      message: filterIndividualMessages(item.product.document_id, item.sku.document_id),
      card: filterIndividualCards(item.product.document_id, item.sku.document_id),
      cartDetail: item,
    }
  })
  return giftPatterns
})

const openSelectGift = async (skuId: string, isNotGift: boolean = false): Promise<void> => {
  selectGiftSkuId.value = skuId
  isNotWrappingSelectGift.value = isNotGift
  await fetchGiftPattern() // 取得回数が多くなる場合は都度取得しないようにする
  visibleSelectGift.value = true
}

const selectGiftCart = computed(() =>
  cartListWithGift.value.find((item) => item.cartDetail.sku.document_id === selectGiftSkuId.value),
)

const wrappingConfirm = async (tempSelectionGift: CartGift, isCard: boolean): Promise<void> => {
  if (!isCard && !tempSelectionGift.sku_id) {
    toast?.showErrorToasted("ラッピングを選択して下さい")
    return
  } else if (isCard && !tempSelectionGift.card_sku_id) {
    toast?.showErrorToasted("カードを選択して下さい")
    return
  }
  const cartItem = cloneDeep(selectGiftCart.value?.cartDetail)
  if (!cartItem) return
  await setWrapping({ skuId: cartItem.sku.document_id, gift: tempSelectionGift })
  visibleSelectGift.value = false
  selectGiftSkuId.value = ""
  toast?.showSuccessToasted(`${isCard ? "メッセージカード" : "ギフトラッピング"}の設定を完了しました`)
}

const cancelGiftModal = (): void => {
  // モーダルを閉じる
  visibleSelectGift.value = false
  selectGiftSkuId.value = ""
}

const getCartItems = (skuIds: string[]) => {
  cartItems.value = []
  skuIds.forEach((skuId) => {
    const item = cartLine.value.find((cartItem) => cartItem.sku.document_id === skuId)
    if (item) {
      cartItems.value.push(item)
      // 刺繍、ラッピング、ラッピング商品、まとめてラッピングのいずれかがある場合はラッピング不可
      if (
        item.gifts?.length ||
        item.embroideries?.length ||
        item.product.is_gift ||
        item.group_wrappings?.length ||
        item.group_wrapped_uniq_id
      ) {
        canWrapping.value = false
      }
    }
  })
}

const duvetCollectionType = computed(() => getDuvetCollectionType())

watch(
  () => cartState.addCartModalSkuIds,
  (skuIds) => {
    if (skuIds.length) {
      updateRecommendProducts()
      getCartItems(skuIds)
    }
  },
  { immediate: true },
)

watch(
  () => store.cartList.length,
  (value: number) => {
    if (value === 0 && store.eGift.is_select) store.resetEGift()
  },
)
</script>

<style lang="scss" scoped module>
.add_cart_modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 5rem;
  @include md {
    margin-bottom: 0;
  }
  .product_item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
    .product_detail {
      display: flex;
      gap: 0.5rem;
      align-items: flex-start;
      .img {
        aspect-ratio: 184 / 245;
        border-radius: 0.375rem;
        object-fit: cover;
      }
      .product_detail_content {
        width: 100%;

        .product_title {
          h2 {
            font-size: $font-size-16;
          }
          p {
            font-size: $font-size-14;
          }
        }
        .sku_detail {
          font-size: $font-size-14;
        }
        .product_price {
          line-height: 18px;
          text-align: right;
          .price {
            @include main-font;
            font-size: $font-size-20;
            span {
              font-size: $font-size-12;
            }
          }
          .strike {
            margin-top: 0.25rem;
            text-decoration: line-through;
            font-size: $font-size-12;
          }
          .discounted,
          .discount_announce {
            color: $red;
          }
          .discount_announce {
            font-size: $font-size-12;
            font-weight: normal;
            @include md {
              font-size: $font-size-14;
            }
          }
        }

        .duvet_collection_setting {
          border: 1px solid $primary-40;
          border-radius: 6px;
          cursor: pointer;
          padding: 10px;
          margin: 10px 0;

          &.fit_content {
            width: fit-content;
          }

          .duvet_collection_setting_none {
            display: flex;
            align-items: center;
            gap: 5px;

            p {
              vertical-align: bottom;
            }
          }

          .duvet_collection_setting_text_1 {
            font-size: $font-size-12;
            color: $primary-100;
            font-weight: bold;
          }

          .duvet_collection_setting_text_2 {
            font-size: $font-size-12;
            color: $font-color-primary;
            font-weight: bold;
          }

          .duvet_collection_setting_btn {
            font-size: $font-size-12;
            padding: 5px;
          }
        }
      }
    }
  }
  .wrapping_button {
    box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.08);
    border-color: $primary;
    // 背景色を点滅するアニメーション
    animation: blink 2s infinite;
    @keyframes blink {
      0% {
        background-color: $white;
      }
      50% {
        background-color: $primary-10;
      }
      100% {
        background-color: $white;
      }
    }
  }
  .bottom_button {
    position: fixed;
    z-index: 10;
    margin-left: -1rem;
    padding: 1rem;
    background: linear-gradient(#ffffff00, #ffffffaa 20%);
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    @include md {
      margin-left: 0;
      padding: 0;
      position: relative;
      width: 100%;
    }
    .buttons {
      display: flex;
      gap: 0.5rem;
      width: 100%;
      max-width: 440px;
      margin: auto;
      .button_link {
        width: 100%;
      }
    }
  }
}
.gift_modal {
  z-index: 151 !important;
}
</style>
